export const API_PATH = {
  CENTERS: '/centers',
  CITIES: '/cities',
  CONSULTATIONS: '/consultations',
  COUNTRIES: '/countries',
  INCIDENTS: '/incidents',
  JOBS: '/jobs',
  PATIENTS: '/patients',
  ORGANISATIONS: '/organisations',
  REFRESH: '/login/refresh',
  ROLES: '/roles',
  USERS: '/users',
  WAITING_ROOMS: '/waiting-rooms',
};
