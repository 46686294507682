import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { RequestStatus } from 'models/requestStatus.model';
import { useTranslation } from 'react-i18next';

const style: SxProps = {
  margin: 'auto',
  textAlign: 'center',
  width: '35px',
  border: 'solid',
  borderWidth: '1px',
  borderRadius: '5px',
};

const consultationNotRequestOpacity = '0.2';

const ConsultationsValidation = ({
  consultationRequests,
}: {
  consultationRequests?: RequestStatus[];
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: 'auto',
        marginBottom: 'auto',
        display: 'grid',
        columnGap: '8px',
        rowGap: '8px',
      }}
    >
      <Typography
        sx={{
          ...style,
          gridRow: 1,
          gridColumn: 1,
          opacity:
            consultationRequests?.includes(RequestStatus.DoctorConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingDoctor)
              ? '1'
              : consultationNotRequestOpacity,
          backgroundColor: consultationRequests?.includes(RequestStatus.DoctorConsulted)
            ? theme.palette.success.light
            : '',
          borderStyle:
            consultationRequests?.includes(RequestStatus.DoctorConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingDoctor)
              ? 'solid'
              : 'dashed',
        }}
      >
        {t('waitingroom.validation.doctor').toUpperCase()}
      </Typography>
      <Typography
        sx={{
          ...style,
          gridRow: 1,
          gridColumn: 2,
          opacity:
            consultationRequests?.includes(RequestStatus.SocialWorkConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingSocialWork)
              ? '1'
              : consultationNotRequestOpacity,
          backgroundColor: consultationRequests?.includes(RequestStatus.SocialWorkConsulted)
            ? theme.palette.success.light
            : '',
          borderStyle:
            consultationRequests?.includes(RequestStatus.SocialWorkConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingSocialWork)
              ? 'solid'
              : 'dashed',
        }}
      >
        {t('waitingroom.validation.socialWorker').toUpperCase()}
      </Typography>
      <Typography
        sx={{
          ...style,
          gridRow: 1,
          gridColumn: 3,
          opacity:
            consultationRequests?.includes(RequestStatus.PsyConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingPsy)
              ? '1'
              : consultationNotRequestOpacity,
          backgroundColor: consultationRequests?.includes(RequestStatus.PsyConsulted)
            ? theme.palette.success.light
            : '',
          borderStyle:
            consultationRequests?.includes(RequestStatus.PsyConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingPsy)
              ? 'solid'
              : 'dashed',
        }}
      >
        {t('waitingroom.validation.psychologist').toUpperCase()}
      </Typography>
      <Typography
        sx={{
          ...style,
          gridRow: 1,
          gridColumn: 4,
          opacity:
            consultationRequests?.includes(RequestStatus.OtherConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingOther)
              ? '1'
              : consultationNotRequestOpacity,
          backgroundColor: consultationRequests?.includes(RequestStatus.OtherConsulted)
            ? theme.palette.success.light
            : '',
          borderStyle:
            consultationRequests?.includes(RequestStatus.OtherConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingOther)
              ? 'solid'
              : 'dashed',
        }}
      >
        {t('waitingroom.validation.other').toUpperCase()}
      </Typography>
    </Box>
  );
};

export default ConsultationsValidation;
