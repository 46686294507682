import { TanstackQueryOptions } from 'models/api.model';
import { Incident } from 'models/incident.model';
import useApi from 'api/useApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from './queryKeys';
import { API_PATH } from './paths';

export const useQueryIncident = (id: string, options?: TanstackQueryOptions<Incident>) => {
  const { sendGetRequest } = useApi();

  return useQuery<Incident, Error>(
    [QUERY_KEYS.INCIDENT.ONE, id],
    async () => sendGetRequest<Incident>(`${API_PATH.INCIDENTS}/${id}`),
    options,
  );
};

export const useQueryIncidents = (
  patientId: string,
  options?: TanstackQueryOptions<Incident[]>,
) => {
  const { sendGetRequest } = useApi();

  return useQuery<Incident[], Error>(
    [QUERY_KEYS.INCIDENT.ALL, patientId],
    async () => sendGetRequest<Incident[]>(`${API_PATH.INCIDENTS}?patientId=${patientId}`),
    options,
  );
};

export const useCreateIncident = (patientId: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (incident: Incident) => {
      incident = { ...incident, patientId: patientId };
      return sendRequestWithBody<Incident>('POST', API_PATH.INCIDENTS, incident);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
      },
    },
  );
};

export const useModifyIncident = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (incident: Incident) =>
      sendRequestWithBody<Incident>('PUT', `${API_PATH.INCIDENTS}/${id}`, incident),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ONE, id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
      },
    },
  );
};

export const useDeleteIncident = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (incidentId: string) => sendDeleteRequest(`${API_PATH.INCIDENTS}/${incidentId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.INCIDENT.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
      },
    },
  );
};
