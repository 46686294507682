import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import PatientTable from 'components/PatientTable';
import PatientModal from 'components/modals/PatientModal';
import SearchBar from 'components/shared/SearchBar';
import WaitingRoomList from 'components/waitingRoom/WaitingRoomList';
import WaitingRoomModal from 'components/waitingRoom/WaitingRoomModal';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import StyledButton from 'components/StyledButton';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE } from 'router/routes';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useQueryCountForbiddenPatient } from 'api';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdition, setIsEdition] = useState(false);
  const [id, setId] = useState<string>('');
  const [filter, setFilter] = useState<string>('');

  const [isOpenWaitingRoom, setIsOpenWaitingRoom] = useState(false);
  const [waitingRoomId, setWaitingRoomId] = useState<string>('');
  const { t } = useTranslation();

  const [isForbidden, setIsForbidden] = useState(false);
  const { data } = useQueryCountForbiddenPatient();

  const { isOrganisationMember, centerId } = useContext(AuthenticationContext);

  useEffect(() => setIsOpenWaitingRoom(false), [centerId]);

  return (
    <Box
      component="main"
      sx={{
        position: 'relative',
        display: 'flex',
        gridTemplateColumns: '3fr 1fr',
        gridTemplateRows: '40px 1fr',
        gridColumnGap: '24px',
        gridRowGap: '32px',
        p: '32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gap: '1rem',
        }}
      >
        <Box
          sx={{
            gridRow: 1,
            gridColumn: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <SearchBar
              onSearch={(value) => {
                setIsForbidden(false);
                setFilter(value);
              }}
              placeholder={t('patient.search')}
            />
            <Tooltip
              title={
                <Fragment>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                    {t('patient.helpSearch.title')}
                  </Typography>
                  <Typography>
                    <Trans i18nKey="patient.helpSearch.content" />
                  </Typography>
                </Fragment>
              }
              arrow
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <StyledButton
              startIcon={<LockOutlinedIcon />}
              variant="outlined"
              onClick={() => setIsForbidden(!isForbidden)}
              sx={{ fontWeight: isForbidden ? 'bold' : 'normal' }}
            >
              {isForbidden
                ? t('patient.hideBannedPatients', { number: data })
                : t('patient.displayBannedPatients', { number: data })}
            </StyledButton>

            {!isOrganisationMember && (
              <StyledButton
                startIcon={<PersonAddAltOutlinedIcon />}
                variant="contained"
                onClick={() => {
                  setIsEdition(false);
                  setIsOpen(true);
                }}
              >
                {t('patient.add')}
              </StyledButton>
            )}
          </Box>
        </Box>

        <PatientTable
          sx={{ gridRow: 2, gridColumn: 1 }}
          filter={filter}
          isForbidden={isForbidden}
          editPatient={(id: string) => {
            setId(id);
            setIsEdition(true);
            setIsOpen(true);
          }}
        />

        <PatientModal
          id={id}
          isEdition={isEdition}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      </Box>

      {!isOrganisationMember && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
          <Link component={RouterLink} to={ROUTE.HISTORY}>
            <StyledButton startIcon={<DensitySmallIcon />} variant="contained">
              {t('action.history')}
            </StyledButton>
          </Link>
          <WaitingRoomList
            sx={{
              height: 'calc(100% - 56px)',
              width: '350px',
              minWidth: '250px',
              flex: '0 1 auto',
            }}
            onClick={(waitingroomId) => {
              setWaitingRoomId(waitingroomId);
              setIsOpenWaitingRoom(true);
            }}
          />
        </Box>
      )}

      {!isOrganisationMember && (
        <WaitingRoomModal
          isOpen={isOpenWaitingRoom}
          waitingRoomId={waitingRoomId}
          onClose={() => {
            setIsOpenWaitingRoom(false);
            setWaitingRoomId('');
          }}
        />
      )}
    </Box>
  );
};
export default HomePage;
