import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const useUserValidator = () => {
  const { t } = useTranslation();
  const userValidator = useMemo(
    () =>
      z.object({
        id: z.string(),
        lastname: z
          .string()
          .min(1, { message: t('form.required') })
          .max(100, { message: t('form.maxSize', { max: 100 }) }),
        firstname: z
          .string()
          .min(1, { message: t('form.required') })
          .max(20, { message: t('form.maxSize', { max: 20 }) }),
        ssn: z.string().length(20, { message: t('form.requiredSize', { size: 20 }) }),
        roleId: z.string(),
        jobId: z.string().min(1, { message: t('form.required') }),
        organisationId: z.string().nullable(),
        status: z.boolean(),
      }),
    [t],
  );
  return userValidator;
};
export default useUserValidator;
