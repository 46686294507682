import { Box, IconButton, Modal, Typography } from '@mui/material';
import { ModalContext } from 'components/ModalProvider';
import CustomForm from 'components/forms/CustomForm';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormModalProps } from 'models/form.models';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StyledButton from 'components/StyledButton';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

const FormModal = <T extends object>({
  title,
  control,
  fields,
  isDirty,
  isOpen,
  isEdition,
  isLoading,
  cancelModalTitle,
  children,
  validationButtonProps,
  cancelButtonProps,
  headerActions,
  onValid,
  handleClose,
}: FormModalProps<T>) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  const onCancel = useCallback(() => {
    if (isDirty) {
      openModal({
        title: cancelModalTitle ?? t('action.cancel'),
        message: t('modals.cancelFormConfirmation'),
        validButton: {
          label: t('action.confirmAbandon'),
          icon: <DeleteForeverIcon />,
          onClick: () => {
            handleClose();
          },
        },
        cancelButton: {
          label: t('action.cancelAbandon'),
        },
      });
    } else {
      handleClose();
    }
  }, [isDirty, handleClose, t, cancelModalTitle]);

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          padding: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxHeight: '90vh',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          color: 'primary.main',
          fontSize: { xs: '1rem', xl: '1.15rem' },
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '1.25em',
            color: 'gray1.main',
          }}
        >
          {title}
        </Typography>

        <Box sx={{ position: 'absolute', top: '8px', right: '8px', color: 'darkText.main' }}>
          {headerActions && headerActions}
          <IconButton
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          component="form"
          sx={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridColumnGap: '16px',
            gridRowGap: '16px',
          }}
        >
          {children}
          <CustomForm fields={fields} control={control} isLoading={isLoading} />
        </Box>

        <Box
          component="span"
          sx={{
            m: 1,
            justifyContent: 'center',
            display: 'flex',
            gap: '50px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StyledButton
            variant="outlined"
            startIcon={cancelButtonProps?.icon ?? <CloseIcon />}
            onClick={onCancel}
          >
            {cancelButtonProps?.text ?? t('action.cancel')}
          </StyledButton>
          <StyledButton
            onClick={onValid}
            startIcon={validationButtonProps?.icon ?? <DoneIcon />}
            type="submit"
            variant="contained"
            disabled={validationButtonProps?.isDisabled}
          >
            {validationButtonProps?.text ?? (isEdition ? t('action.save') : t('action.add'))}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};
export default FormModal;
