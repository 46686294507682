import FormModal from './FormModal';
import useCustomForm from 'hooks/useCustomForm';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Patient } from 'models/patient.model';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from 'components/NotificationProvider';
import { CustomFormModalProps } from 'models/modal.model';
import { Box, Button, IconButton, Tooltip, useTheme, Typography } from '@mui/material';
import { CustomField } from 'models/form.models';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {
  useQueryPatient,
  useCreatePatient,
  useModifyPatient,
  useDeletePatient,
  useQueryOrganisations,
  useQueryPatients,
} from 'api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import usePatientValidator from 'validators/usePatientValidator';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { ReactComponent as AddWaitingIcon } from 'assets/add_waiting.svg';
import { ModalContext } from 'components/ModalProvider';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Roles } from 'models/role.model';
import ConsultationRequestModal from './ConsultationRequestModal';
import { WaitingRoomStatus } from 'models/waitingRoom.model';
import DuplicatedPatientModal from './DuplicatedPatientModal';

const emptyPatient: Patient = {
  id: '',
  firstname: '',
  lastname: '',
  dsiNumber: '',
  birthDate: null,
  serialNumber: '',
  email: '',
  contactNumber: null,
  remark: '',
  hasGivenAuthorisation: false,
  isBan: false,
  banStartDate: null,
  banEndDate: null,
  isInWaitingRoom: false,
  organisationsIds: [],
  lastConsultationCenter: null,
  lastConsultationDate: null,
};

const PatientModal = ({ id, isEdition, isOpen, onClose }: CustomFormModalProps<Patient>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { roles, isOrganisationMember } = useContext(AuthenticationContext);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [isConsultationRequestsOpen, setIsConsultationRequestsOpen] = useState(false);
  const [hasDuplicatedPatient, setHasDuplicatedPatient] = useState(false);

  const { openModal } = useContext(ModalContext);
  const cancelModalTitle = useMemo(
    () =>
      t(isEdition ? 'modals.patientForm.title.edit' : 'modals.patientForm.title.add', {
        returnObjects: true,
      }),
    [t],
  );

  const { isLoading: arePatientsLoading, data } = useQueryPatient(id, { enabled: isEdition });
  const { mutateAsync: createPatient } = useCreatePatient();
  const { mutateAsync: modifyPatient } = useModifyPatient(id);
  const { mutateAsync: deletePatient } = useDeletePatient();
  const { isLoading: areOrganisationsLoading, data: organisations } = useQueryOrganisations();

  const isLoading = useMemo(
    () => arePatientsLoading || areOrganisationsLoading,
    [arePatientsLoading, areOrganisationsLoading],
  );

  const patientValidator = usePatientValidator();
  const { control, handleSubmit, isDirty, register, errors, setValue, getValues, isValid } =
    useCustomForm<Patient>({
      isEdition,
      isOpen,
      data: isEdition ? data : emptyPatient,
      schema: patientValidator,
      mutate: isEdition ? modifyPatient : createPatient,
    });

  const { data: patientsList } = useQueryPatients(
    0,
    10,
    `${getValues().firstname} ${getValues().lastname}`,
  );

  const fields: CustomField[] = [
    {
      type: 'text',
      hasError: Boolean(errors.dsiNumber),
      helperText: errors.dsiNumber?.message,
      gridRow: 2,
      gridColumn: 1,
      label: t('patient.dsiNumber'),
      register: register('dsiNumber'),
      isRequired: !patientValidator.shape.dsiNumber.isNullable(),
      isReadonly: isEdition,
      maxLength: 12,
      endIcon: isEdition ? (
        <Tooltip title={t('action.copy')} arrow>
          <IconButton onClick={() => navigator.clipboard.writeText(getValues().dsiNumber)}>
            <ContentCopyIcon>{t('action.copy')}</ContentCopyIcon>
          </IconButton>
        </Tooltip>
      ) : (
        !!navigator.clipboard.readText && (
          <Tooltip title={t('action.paste')} arrow>
            <IconButton
              onClick={async () => {
                setValue('dsiNumber', await navigator.clipboard.readText());
              }}
            >
              <ContentPasteIcon color="primary" />
            </IconButton>
          </Tooltip>
        )
      ),
    },
    {
      type: 'text',
      hasError: Boolean(errors.lastname),
      helperText: errors.lastname?.message,
      gridRow: 3,
      gridColumn: 1,
      label: t('patient.lastname'),
      register: register('lastname'),
      isRequired: !patientValidator.shape.lastname.isNullable(),
      maxLength: 100,
    },
    {
      type: 'text',
      hasError: Boolean(errors.firstname),
      helperText: errors.firstname?.message,
      gridRow: 3,
      gridColumn: 2,
      label: t('patient.firstname'),
      register: register('firstname'),
      isRequired: !patientValidator.shape.firstname.isNullable(),
      maxLength: 20,
    },
    {
      type: 'date',
      hasError: Boolean(errors.birthDate),
      helperText: errors.birthDate?.message,
      gridRow: 4,
      gridColumn: 1,
      label: t('patient.birthdate'),
      register: register('birthDate'),
      isRequired: !patientValidator.shape.birthDate.isNullable(),
    },
    {
      type: 'text',
      hasError: Boolean(errors.serialNumber),
      helperText: errors.serialNumber?.message,
      gridRow: 4,
      gridColumn: 2,
      label: t('patient.serialNumber'),
      register: register('serialNumber'),
      maxLength: 20,
      isRequired: !patientValidator.shape.serialNumber.isNullable(),
    },
    {
      type: 'text',
      hasError: Boolean(errors.email),
      helperText: errors.email?.message,
      gridRow: 5,
      gridColumn: 1,
      label: t('patient.email'),
      register: register('email'),
      isRequired: !patientValidator.shape.email.isNullable(),
    },
    {
      type: 'tel',
      hasError: Boolean(errors.contactNumber),
      helperText: errors.contactNumber?.message,
      gridRow: 5,
      gridColumn: 2,
      label: t('patient.contactNumber'),
      register: register('contactNumber'),
      isRequired: !patientValidator.shape.contactNumber.isNullable(),
    },
    {
      type: 'text',
      hasError: Boolean(errors.remark),
      helperText: errors.remark?.message,
      gridRow: 8,
      gridColumn: isOrganisationMember ? '1/3' : '1/4',
      label: t('patient.remark'),
      register: register('remark'),
      isRequired: !patientValidator.shape.remark.isNullable(),
      rows: 4,
      isMultiline: true,
    },
    {
      type: 'boolean',
      hasError: Boolean(errors.hasGivenAuthorisation),
      helperText: errors.hasGivenAuthorisation?.message,
      gridRow: 9,
      gridColumn: isOrganisationMember ? '1/3' : '1/4',
      label: t('patient.hasGivenAuthorisation'),
      register: register('hasGivenAuthorisation'),
    },
  ];

  if (!isOrganisationMember) {
    fields.push(
      {
        type: 'multiselect',
        hasError: Boolean(errors.organisationsIds),
        helperText: errors.organisationsIds?.message,
        gridRow: '3/7',
        gridColumn: 3,
        label: t('patient.organisationsIds'),
        register: register('organisationsIds'),
        options:
          organisations?.map((organisation) => ({
            value: organisation.id,
            label: organisation.name,
          })) ?? [],
      },
      {
        type: 'text',
        gridRow: 2,
        gridColumn: 2,
        label: t('patient.lastConsultationCenter'),
        register: register('lastConsultationCenter'),
        isReadonly: true,
        isHidden: !isEdition,
      },
      {
        type: 'date',
        gridRow: 2,
        gridColumn: 3,
        label: t('patient.lastConsultationDate'),
        register: register('lastConsultationDate'),
        isReadonly: true,
        isHidden: !isEdition,
      },
    );
  }

  if (isEdition) {
    fields.push(
      {
        type: 'boolean',
        hasError: Boolean(errors.isBan),
        helperText: errors.isBan?.message,
        gridRow: 6,
        gridColumn: isOrganisationMember ? '1/3' : '1/4',
        label: t('patient.isBan'),
        register: register('isBan'),
        isRequired: !patientValidator.shape.isBan.isNullable(),
        isHidden: !isEdition,
      },
      {
        type: 'date',
        hasError: Boolean(errors.banStartDate),
        helperText: errors.banStartDate?.message,
        gridRow: 7,
        gridColumn: 1,
        label: t('patient.banStartDate'),
        register: register('banStartDate'),
        isRequired: !patientValidator.shape.banStartDate.isNullable(),
        isHidden: !getValues().isBan,
      },
      {
        type: 'date',
        hasError: Boolean(errors.banEndDate),
        helperText: errors.banEndDate?.message,
        gridRow: 7,
        gridColumn: 2,
        label: t('patient.banEndDate'),
        register: register('banEndDate'),
        isRequired: !patientValidator.shape.banEndDate.isNullable(),
        isHidden: !getValues().isBan,
      },
    );
  }

  if (isEdition && isReadOnly) {
    fields.forEach((field) => (field.isReadonly = true));
  }

  const handleDelete = useCallback(
    () =>
      data?.id &&
      openModal({
        title: t('modals.deletePatient.title', {
          lastname: data?.lastname,
          firstname: data?.firstname,
        }),
        message: t('modals.deletePatient.message'),
        validButton: {
          icon: <DeleteForeverIcon />,
          label: t('action.delete'),
          onClick: async () => {
            await deletePatient(data.id);
            sendNotification(t('notifications.patientTable.delete'), 'success');
            onClose();
          },
        },
        cancelButton: {
          label: t('action.cancel'),
        },
      }),
    [data, deletePatient, openModal, sendNotification, t],
  );

  const handleClose = () => {
    setIsReadOnly(true);
    onClose();
  };

  const onSaved = useCallback(() => {
    sendNotification(
      t(isEdition ? 'notifications.patientTable.modify' : 'notifications.patientTable.add'),
      'success',
    );
    setIsReadOnly(true);
    onClose();
  }, [onClose, sendNotification, t]);

  return (
    <FormModal<Patient>
      title={isEdition ? t('patient.edit') : t('patient.add')}
      control={control}
      isEdition={isEdition}
      isOpen={isOpen}
      onValid={
        isEdition
          ? isReadOnly
            ? () => setIsConsultationRequestsOpen(true)
            : handleSubmit(onSaved)
          : isValid && patientsList?.content.length !== 0
          ? () => setHasDuplicatedPatient(true)
          : handleSubmit(onSaved)
      }
      validationButtonProps={
        isEdition && isReadOnly
          ? {
              text: t('waitingroom.send'),
              icon: (
                <AddWaitingIcon
                  style={{
                    fill: data?.isInWaitingRoom
                      ? 'rgba(0, 0, 0, 0.26)'
                      : theme.palette.background.paper,
                  }}
                />
              ),
              isDisabled: data?.isInWaitingRoom ?? false,
            }
          : {}
      }
      cancelButtonProps={isEdition && isReadOnly ? { text: t('action.closeModal') } : {}}
      isDirty={isDirty}
      fields={fields}
      handleClose={handleClose}
      isLoading={isEdition && isLoading}
      cancelModalTitle={cancelModalTitle}
      headerActions={
        isEdition && isReadOnly ? (
          <>
            {roles.includes(Roles.CenterAdministrator) && (
              <Button onClick={handleDelete} startIcon={<DeleteForeverIcon />}>
                {t('action.delete')}
              </Button>
            )}
            <Button onClick={() => setIsReadOnly(false)} startIcon={<EditIcon />}>
              {t('action.edit')}
            </Button>
          </>
        ) : (
          <></>
        )
      }
    >
      <>
        {isEdition && isReadOnly ? (
          <>
            {data?.isBan ? (
              <Box
                sx={{
                  gridRow: 1,
                  gridColumn: isOrganisationMember ? '1/3' : '1/4',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.background.paper,
                    fontSize: '16px',
                    fontWeight: '700',
                    backgroundColor: 'red',
                    padding: '8px',
                    borderRadius: '5px',
                  }}
                >
                  {t('patient.isForbidden')}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {data && (
          <ConsultationRequestModal
            isOpen={isConsultationRequestsOpen}
            isEdition={false}
            waitingRoom={{
              id: '',
              createdDate: new Date(),
              updatedDate: new Date(),
              status: WaitingRoomStatus.Waiting,
              remark: '',
              patient: {
                id: data.id,
                dsiNumber: data.dsiNumber,
                firstname: data.firstname,
                lastname: data.lastname,
                isIncident: false,
              },
              consultationRequests: [],
            }}
            onClose={() => setIsConsultationRequestsOpen(false)}
          />
        )}
        <DuplicatedPatientModal
          isOpen={hasDuplicatedPatient}
          patient={getValues()}
          onClose={() => {
            setHasDuplicatedPatient(false);
            onClose();
          }}
        />
      </>
    </FormModal>
  );
};
export default PatientModal;
