import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import { ReactComponent as WaitingIcon } from 'assets/WaitingIcon.svg';
import { ReactComponent as DoctorIcon } from 'assets/DoctorIcon.svg';
import { ReactComponent as SocialWorkerIcon } from 'assets/SocialWorkerIcon.svg';
import { ReactComponent as OtherIcon } from 'assets/OtherIcon.svg';
import { ReactComponent as PsychologyIcon } from 'assets/PsychologyIcon.svg';
import {
  WAITING_ROOM_COLOR,
  WaitingRoomCardProps,
  WaitingRoomStatus,
} from 'models/waitingRoom.model';
import { useMemo } from 'react';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import ConsultationsValidation from './ConsultationsValidation';

const WaitingRoomCard = ({
  status,
  firstname,
  lastname,
  isIncident,
  consultationRequests,
  onClick,
}: WaitingRoomCardProps) => {
  const { t } = useTranslation();
  const dynamicTranslation = useDynamicTranslation();
  const statusLabel = useMemo(() => {
    const key = `modals.waitingRoom.status.${status.charAt(0).toLowerCase()}${status.slice(1)}`;
    return dynamicTranslation(key) ?? '';
  }, [status, dynamicTranslation]);

  const iconProps = useMemo(() => {
    switch (status) {
      case WaitingRoomStatus.ConsultingDoctor:
        return { component: DoctorIcon, viewBox: '0 0 32 32' };
      case WaitingRoomStatus.ConsultingSocialWorker:
        return { component: SocialWorkerIcon, viewBox: '0 0 33 33' };
      case WaitingRoomStatus.ConsultingOther:
        return { component: OtherIcon, viewBox: '0 0 29 29' };
      case WaitingRoomStatus.ConsultingPsychologist:
        return { component: PsychologyIcon, viewBox: '0 -960 960 960' };
      default:
        return { component: WaitingIcon, viewBox: '0 0 24 24' };
    }
  }, [status]);

  return (
    <Box
      onClick={onClick}
      sx={{
        'overflow': 'hidden',
        'position': 'relative',
        'minHeight': '56px',
        'display': 'flex',
        'flexDirection': 'row',
        'alignItems': 'center',
        'gap': '24px',
        'backgroundColor': `color-mix(in srgb, ${WAITING_ROOM_COLOR[status]} 5%, #FFF)`,
        'borderRadius': '15px',
        'color': 'gray1.main',
        'boxShadow': '5px 5px 10px rgba(0, 0, 0, 0.05)',
        ':hover': {
          cursor: 'pointer',
        },
        '& .svg-waiting-room:hover + div': {
          width: '100%',
          borderRadius: '15px',
        },
      }}
    >
      <Box
        className="svg-waiting-room"
        sx={{ zIndex: 2, padding: '16px 0 16px 34px', height: '100%' }}
      >
        <SvgIcon {...iconProps} sx={{ fontSize: '24px', transition: '0.2s' }} />
      </Box>
      <Box
        className="card-color-box"
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '8px',
          height: '100%',
          borderRadius: '15px 0 0 15px',
          backgroundColor: WAITING_ROOM_COLOR[status],
          transition: '0.69s',
          overflow: 'hidden',
          zIndex: 1,
        }}
      >
        <Typography
          sx={{
            position: 'absolute',
            left: '80px',
            textAlign: 'center',
            verticalAlign: 'center',
            color: '#000000',
            fontWeight: 600,
            whiteSpace: 'nowrap',
          }}
        >
          {statusLabel}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box>
          <Box
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            {!lastname && !firstname ? (
              <Typography fontStyle={'italic'}>
                {t('waitingroom.patientDeletedPlaceholder')}
              </Typography>
            ) : (
              <Typography>{`${lastname?.toLocaleUpperCase()} ${firstname}`}</Typography>
            )}
          </Box>
          <ConsultationsValidation consultationRequests={consultationRequests} />
        </Box>
        {isIncident && (
          <Tooltip title={t('waitingroom.incident')} arrow>
            <WarningAmberIcon color="error" fontSize="large" sx={{ marginRight: '10%' }} />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
export default WaitingRoomCard;
