import { z } from 'zod';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useIncidentValidator = () => {
  const { t } = useTranslation();
  const incidentValidator = useMemo(
    () =>
      z.object({
        id: z.string(),
        description: z.string(),
        date: z.coerce.date().nullable(),
        patientId: z.string(),
      }),
    [t],
  );

  return incidentValidator;
};

export default useIncidentValidator;
