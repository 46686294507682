import { Box, Button, Modal, Tooltip, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import { useCreatePatient, useQueryPatients } from 'api';
import { NotificationContext } from 'components/NotificationProvider';
import StyledButton from 'components/StyledButton';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import { Patient } from 'models/patient.model';
import { useContext, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataGridCustom from 'components/dataGrid/DataGridCustom';
import { TableState } from 'models/dataGridCustom.model';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const DuplicatedPatientModal = ({
  isOpen,
  patient,
  onClose,
}: {
  isOpen: boolean;
  patient: Patient;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { sendNotification } = useContext(NotificationContext);
  const dynamicTranslation = useDynamicTranslation();
  const { mutateAsync: createPatient } = useCreatePatient();

  const [pageState, setPageState] = useState<TableState>({
    page: 0,
    pageSize: 3,
  });

  const { isLoading, data } = useQueryPatients(
    pageState.page,
    pageState.pageSize,
    `${patient.firstname} ${patient.lastname}`,
    { cacheTime: 0 },
  );

  const columns: GridColDef[] = [
    {
      field: 'dsiNumber',
      width: 100,
      headerName: t('patient.dsiNumber'),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => (
        <Tooltip title={t('action.copy')} arrow>
          <Button
            endIcon={<ContentCopyIcon color="primary" sx={{ margin: 'auto' }} />}
            variant="text"
            onClick={() => navigator.clipboard.writeText(params.row.dsiNumber)}
          >
            {t('action.copy')}
          </Button>
        </Tooltip>
      ),
    },
    {
      field: 'firstname',
      headerName: t('patient.firstname'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: t('patient.lastname'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'birthDate',
      headerName: t('patient.birthdate'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => (
        <Tooltip
          title={params.value ? new Date(params.row.birthDate).toLocaleDateString() : ''}
          arrow
        >
          <Typography sx={{ maxWidth: '100%' }}>
            {params.value ? new Date(params.row.birthDate).toLocaleDateString() : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      headerName: t('patient.email'),
      sortable: false,
      flex: 2,
    },
    {
      field: 'contactNumber',
      headerName: t('patient.contactNumber'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'serialNumber',
      headerName: t('patient.serialNumber'),
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          padding: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          color: 'primary.main',
          width: '50vw',
          minWidth: '420px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '1.25em',
            color: 'gray1.main',
          }}
        >
          {t('patient.add')}
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            color: theme.palette.error.main,
            border: 'solid',
            borderWidth: '1px',
            borderRadius: '5px',
          }}
        >
          <Typography sx={{ fontSize: '20px' }}>{t('patient.duplicateTitle')}</Typography>
          <Typography>{t('patient.duplicateText')}</Typography>
        </Box>
        <DataGridCustom
          sx={{
            'height': '500px',
            'backgroundColor': 'transparent',
            'width': '100%',
            '& .custom-data-grid-row': {
              backgroundColor: 'background.paper',
              borderBottom: 'none',
            },
          }}
          isPaginated={true}
          data={data}
          isLoading={isLoading}
          state={pageState}
          columns={columns}
          getRowId={(row) => row.id}
          onPaginationModelChange={(newPageModel: TableState) => {
            setPageState({
              page: newPageModel.page,
              pageSize: newPageModel.pageSize,
            });
          }}
        />
        <Box
          component="span"
          sx={{
            justifyContent: 'center',
            display: 'flex',
            gap: '50px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StyledButton variant="outlined" onClick={() => onClose()}>
            {t('patient.creationCancel')}
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={async () => {
              try {
                await createPatient(patient);
                sendNotification(t('notifications.patientTable.add'), 'success');
                onClose();
              } catch (e) {
                const message = (e as Error).message;
                sendNotification(dynamicTranslation(`error.${message}`) ?? message, 'error');
              }
            }}
          >
            {t('patient.creationConfirm')}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DuplicatedPatientModal;
