import {
  Box,
  Button,
  IconButton,
  Skeleton,
  SvgIcon,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { NotificationContext } from 'components/NotificationProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangingStatus, WaitingRoomStatus } from 'models/waitingRoom.model';
import { useDeleteWaitingRoom, useChangeStatusWaitingRoom, useQueryWaitingRoom } from 'api';
import { ReactComponent as ValidIcon } from 'assets/ValidateIcon.svg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CloseIcon from '@mui/icons-material/Close';
import StatusButton from './StatusButton';
import ConsultationsList from 'components/waitingRoom/ConsultationsList';
import StatusModal from 'components/modals/StatusModal';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import StyledButton from 'components/StyledButton';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IncidentModal from 'components/modals/IncidentModal';
import IncidentTable from 'components/IncidentTable';
import { useTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ConsultationRequestModal from 'components/modals/ConsultationRequestModal';

type WaitingRoomModalProps = {
  isOpen: boolean;
  waitingRoomId: string;
  isListHistoricized?: boolean;
  onClose: () => void;
  sx?: SxProps;
};

const WaitingRoomModal = ({
  isOpen,
  waitingRoomId,
  isListHistoricized = false,
  onClose,
  sx,
}: WaitingRoomModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dynamicTranslation = useDynamicTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { mutateAsync: deleteWaitingRoom } = useDeleteWaitingRoom();
  const { mutateAsync: changeStatusWaitingRoom } = useChangeStatusWaitingRoom(waitingRoomId);
  const isNotEmpty = useMemo(() => waitingRoomId.trim().length > 0, [waitingRoomId]);
  const { data } = useQueryWaitingRoom(waitingRoomId, {
    enabled: isNotEmpty,
  });

  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isOpenLeft, setIsOpenLeft] = useState(false);
  const [remark, setRemark] = useState('');

  const [isIncidentOpen, setIsIncidentOpen] = useState(false);
  const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false);
  const [incidentId, setIncidentId] = useState<string>('');
  const [isEditionIncident, setIsEditionIncident] = useState(false);

  const [isEditionConsultation, setIsEditionConsultation] = useState(false);

  const isEditable = useMemo(
    () =>
      data?.status &&
      !isListHistoricized &&
      ![WaitingRoomStatus.EndConsultation, WaitingRoomStatus.Refused].includes(data?.status),
    [data?.status],
  );

  useEffect(() => setIsIncidentOpen(false), [waitingRoomId]);

  const endConsultation = useCallback(async () => {
    await deleteWaitingRoom(waitingRoomId);
    sendNotification(t('notifications.waitingRoom.delete'), 'success');
    onClose();
  }, [deleteWaitingRoom, sendNotification, onClose, waitingRoomId]);

  const changeStatus = useCallback(
    async (status: WaitingRoomStatus, remark: string) => {
      if (data) {
        const waitingRoom = { ...data, status, remark };
        await changeStatusWaitingRoom(waitingRoom);
        if (status === WaitingRoomStatus.Refused) {
          sendNotification(t('notifications.waitingRoom.left'), 'success');
        } else {
          sendNotification(t('notifications.waitingRoom.changeStatus'), 'success');
        }
      }
    },
    [changeStatusWaitingRoom, sendNotification, data],
  );

  return isOpen ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '28px',
        left: '20px',
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
        p: '16px',
        gap: '16px',
        color: 'gray1.main',
        fontWeight: '400',
        minWidth: 'calc(75% - 48px)',
        width: 'calc(100% - 414px)',
        height: 'calc(100% - 64px)',
        zIndex: 10,
        overflow: 'auto',
        ...sx,
      }}
    >
      {data?.patient.isIncident && (
        <StyledButton
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            textDecoration: 'none',
            border: 'solid',
            borderWidth: '1px',
            color: theme.palette.warning.dark,
          }}
          onClick={() => setIsIncidentOpen(true)}
        >
          {t('action.incident')}
        </StyledButton>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {isIncidentOpen ? (
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => setIsIncidentOpen(false)}
            sx={{
              color: 'primary.main',
              margin: 0,
              padding: 0,
              width: 'fit-content',
              fontWeight: '600',
              textDecoration: 'none',
            }}
          >
            {t('modals.waitingRoom.return')}
          </Button>
        ) : (
          <Button
            startIcon={!isListHistoricized ? <KeyboardBackspaceIcon /> : <CloseIcon />}
            onClick={onClose}
            sx={{
              color: 'primary.main',
              margin: 0,
              padding: 0,
              width: 'fit-content',
              fontWeight: '600',
              textDecoration: 'none',
            }}
          >
            {!isListHistoricized ? t('action.return') : t('action.close')}
          </Button>
        )}
        {!isListHistoricized && (
          <StyledButton
            variant="contained"
            color="error"
            startIcon={<NotificationsNoneIcon />}
            onClick={() => setIsIncidentModalOpen(true)}
          >
            {t('incident.add')}
          </StyledButton>
        )}
      </Box>

      <Typography
        variant="h6"
        component="h2"
        sx={{
          fontWeight: '700',
          textAlign: 'left',
          width: '200px',
          color: 'gray1.main',
          borderBottom: 1,
        }}
      >
        {t('modals.waitingRoom.patientTitle').toUpperCase()}
      </Typography>

      {data ? (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '16px', textAlign: 'left', marginLeft: '24px' }}>
            {`${data.patient.lastname.toLocaleUpperCase()} ${data.patient.firstname}`}
            <Box component="span" sx={{ marginLeft: '40px' }}>
              {`${t('patient.dsiNumber')} ${data.patient.dsiNumber}`}
              <Tooltip title={t('action.copy')} arrow>
                <IconButton
                  sx={{ marginLeft: '10px' }}
                  onClick={() => navigator.clipboard.writeText(data.patient.dsiNumber)}
                >
                  <ContentCopyIcon color="primary" sx={{ margin: 'auto', color: 'gray1.main' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '40px',
            textAlign: 'left',
            marginLeft: '20px',
            marginTop: '8px',
          }}
        >
          <Skeleton animation={'pulse'} variant="rounded" height={16} width={140} />
          <Skeleton animation={'pulse'} variant="rounded" height={16} width={220} />
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: '700',
            textAlign: 'left',
            width: '200px',
            color: 'gray1.main',
            borderBottom: 1,
          }}
        >
          {isIncidentOpen
            ? t('modals.waitingRoom.incidentTitle').toUpperCase()
            : t('modals.waitingRoom.statusTitle').toUpperCase()}

          {!isIncidentOpen && isEditable && (
            <Button
              startIcon={<EditIcon />}
              sx={{ marginLeft: '64px', p: 0 }}
              onClick={() => setIsEditionConsultation(true)}
            >
              Editer
            </Button>
          )}
        </Typography>
      </Box>
      {isEditable && !isIncidentOpen && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              marginTop: '16px',
              marginLeft: '24px',
              rowGap: '16px',
              gap: '48px',
            }}
          >
            {Object.keys(ChangingStatus).map((status) =>
              data ? (
                <StatusButton
                  key={status}
                  status={status as WaitingRoomStatus}
                  isCheck={data.status === status}
                  onClick={() => {
                    if (!isEditable) {
                      return;
                    }
                    if (status !== data.status) {
                      if (status === WaitingRoomStatus.ConsultingOther) {
                        setIsStatusOpen(true);
                      } else {
                        changeStatus(status as WaitingRoomStatus, remark);
                      }
                    }
                  }}
                />
              ) : (
                <Skeleton
                  key={status}
                  animation={'pulse'}
                  variant="rounded"
                  height={112}
                  width={180}
                />
              ),
            )}
          </Box>
        </>
      )}

      {isIncidentOpen && data ? (
        <IncidentTable
          patientId={data.patient.id}
          editIncident={(id: string) => {
            setIncidentId(id);
            setIsEditionIncident(true);
            setIsIncidentModalOpen(true);
          }}
        />
      ) : data ? (
        <ConsultationsList waitingRoom={data} isDisabled={!isEditable} />
      ) : (
        <Skeleton animation={'pulse'} variant="rounded" height="100%" width="100%" />
      )}

      {isEditable && (
        <Box
          sx={{
            alignSelf: 'flex-end',
            marginBottom: '0',
            margin: 'auto',
            padding: 0,
            width: '485px',
            height: '40px',
          }}
        >
          <Button
            onClick={() => setIsOpenLeft(true)}
            variant="outlined"
            sx={{
              color: 'primary.main',
              margin: 'auto',
              marginBottom: '0',
              borderRadius: '8px',
              alignSelf: 'flex-end',
              padding: 0,
              width: '224px',
              marginRight: '32px',
              height: '40px',
            }}
            startIcon={<DirectionsRunIcon />}
          >
            {t('action.left')}
          </Button>
          <Button
            onClick={endConsultation}
            variant="contained"
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              margin: 'auto',
              marginBottom: '0',
              borderRadius: '8px',
              alignSelf: 'flex-end',
              padding: 0,
              width: '224px',
              height: '40px',
            }}
            startIcon={<SvgIcon component={ValidIcon} viewBox="0 0 12 16" />}
          >
            {t('action.endConsultation')}
          </Button>
        </Box>
      )}

      <StatusModal
        isOpen={isStatusOpen}
        title={t('modals.otherConsultation.title')}
        message={t('modals.otherConsultation.message')}
        label={t('modals.otherConsultation.description')}
        onValid={() => {
          changeStatus(WaitingRoomStatus.ConsultingOther, remark);
          setRemark('');
          setIsStatusOpen(false);
        }}
        onCancel={() => setIsStatusOpen(false)}
        onChange={(event) => setRemark(event.target.value)}
      />

      <StatusModal
        isOpen={isOpenLeft}
        title={t('modals.patientLeft.title')}
        message={t('modals.patientLeft.message')}
        label={t('modals.patientLeft.description')}
        onValid={async () => {
          try {
            await changeStatus(WaitingRoomStatus.Refused, remark);
            setRemark('');
            setIsOpenLeft(false);
            onClose();
          } catch (e) {
            const message = (e as Error).message;
            sendNotification(dynamicTranslation(`error.${message}`) ?? message, 'error');
          }
        }}
        onCancel={() => setIsOpenLeft(false)}
        onChange={(event) => setRemark(event.target.value)}
      />

      {data && (
        <IncidentModal
          id={incidentId}
          isEdition={isEditionIncident}
          isOpen={isIncidentModalOpen}
          onClose={() => {
            setIsIncidentModalOpen(false);
            setIsEditionIncident(false);
          }}
          patientId={data?.patient.id}
        />
      )}

      {data && (
        <ConsultationRequestModal
          isOpen={isEditionConsultation}
          isEdition={true}
          waitingRoom={data}
          onClose={() => setIsEditionConsultation(false)}
        />
      )}
    </Box>
  ) : (
    <></>
  );
};

export default WaitingRoomModal;
