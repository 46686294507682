import { IconButton, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import {
  useDeleteUser,
  useQueryJobs,
  useQueryOrganisations,
  useQueryRoles,
  useQueryUsers,
} from 'api';
import DataGridCustom from 'components/dataGrid/DataGridCustom';
import { TableState } from 'models/dataGridCustom.model';
import { User } from 'models/user.model';
import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NotificationContext } from 'components/NotificationProvider';
import { ModalContext } from 'components/ModalProvider';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import { emptyPage } from 'models/api.model';

const UserTable = ({ filter, editUser }: { filter: string; editUser: (id: string) => void }) => {
  const dynamicTranslation = useDynamicTranslation();
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { openModal } = useContext(ModalContext);
  const [pageState, setPageState] = useState<TableState>({
    page: 0,
    pageSize: 0,
  });
  // Set cache time to 0 to disable request caching
  // since there's a bug with MUI data grid when loading the page with cached data
  const { isLoading: areUsersLoading, data } = useQueryUsers(
    pageState.page,
    pageState.pageSize,
    filter,
    {
      cacheTime: 0,
    },
  );

  const { isLoading: areJobsLoading, data: jobs } = useQueryJobs();
  const { isLoading: areRolesLoading, data: roles } = useQueryRoles();
  const { isLoading: areOrganisationsLoading, data: organisations } = useQueryOrganisations();
  const { mutateAsync: deleteUser } = useDeleteUser();

  const isLoading = useMemo(
    () => areUsersLoading || areJobsLoading || areRolesLoading || areOrganisationsLoading,
    [areUsersLoading, areJobsLoading, areRolesLoading, areOrganisationsLoading],
  );

  const getRoleLabel = useCallback(
    (roleId: string) => {
      const role = roles?.find((role) => role.id === roleId);
      if (!role) {
        return '';
      }
      return (
        dynamicTranslation(`role.${role.name[0].toLowerCase() + role.name.slice(1)}`) ?? role.name
      );
    },
    [roles, dynamicTranslation],
  );

  const columns: GridColDef[] = [
    {
      field: 'firstname',
      headerName: t('user.firstname'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: t('user.lastname'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'job',
      headerName: t('user.job'),
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, User, GridTreeNodeWithRender>) =>
        jobs?.find((job) => job.id === params.row.jobId)?.name ?? '',
    },
    {
      field: 'organisation',
      headerName: t('user.organisation').toString(),
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, User, GridTreeNodeWithRender>) =>
        organisations?.find((organisation) => organisation.id === params.row.organisationId)
          ?.name ?? '',
    },
    {
      field: 'roleId',
      headerName: t('user.roles').toString(),
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, User, GridTreeNodeWithRender>) =>
        getRoleLabel(params.row.roleId),
    },

    {
      field: 'action',
      width: 100,
      headerName: t('action.actions'),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, User, GridTreeNodeWithRender>,
      ) => {
        return (
          <>
            <Tooltip title={t('action.edit')} arrow>
              <IconButton
                onClick={() => {
                  editUser(params.id.toString());
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('action.delete')} arrow>
              <IconButton
                onClick={() => {
                  openModal({
                    title: t('modals.deleteUser.title', {
                      lastname: params.row.lastname,
                      firstname: params.row.firstname,
                    }),
                    message: t('modals.deleteUser.message'),
                    validButton: {
                      icon: <DeleteForeverIcon />,
                      label: t('action.delete'),
                      onClick: async () => {
                        await deleteUser(params.id.toString());
                        sendNotification(t('notifications.userTable.delete'), 'success');
                      },
                    },
                    cancelButton: {
                      label: t('action.cancel'),
                    },
                  });
                }}
              >
                <DeleteForeverIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageState({ ...pageState, page: 0 });
  }, [filter]);

  return (
    <DataGridCustom
      isPaginated={true}
      data={data ?? emptyPage}
      isLoading={isLoading}
      state={pageState}
      columns={columns}
      getRowId={(row) => row.id}
      onPaginationModelChange={(newPageModel: TableState) => {
        setPageState({
          page: newPageModel.page,
          pageSize: newPageModel.pageSize,
        });
      }}
    />
  );
};
export default UserTable;
