import React, { useCallback, useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LanguagePicker from 'components/navbar/LanguagePicker';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'assets/logo_MdM.png';
import Link from '@mui/material/Link';
import { ROUTE } from 'router/routes';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { SvgIcon, useTheme } from '@mui/material';
import CenterPicker from 'components/CenterPicker';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Roles } from 'models/role.model';

const NavBar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { name, dispatchAuthentication } = useContext(AuthenticationContext);
  const { isOrganisationMember, roles } = useContext(AuthenticationContext);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [setAnchorElUser],
  );

  const handleCloseUserMenu = useCallback(
    (onClick?: () => void) => {
      setAnchorElUser(null);
      if (onClick) {
        onClick();
      }
    },
    [setAnchorElUser],
  );

  return (
    <AppBar
      sx={{
        position: 'static',
        to: 0,
        left: 0,
        backgrounColor: 'white',
        height: '64px',
        width: '100svw',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 0,
        padding: '8px 24px',
        background: `linear-gradient(270deg, ${theme.palette.primary.main}FF 85.6%, ${theme.palette.primary.main}D9 97.04%)`,
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px',
          gap: '48px',
          height: '48px',
        }}
      >
        <Link component={RouterLink} to={ROUTE.PATIENTS.INDEX}>
          <Box
            component="img"
            sx={{
              flex: 'none',
              order: '0',
              flexGrow: '0',
              width: '96px',
              height: 'auto',
            }}
            alt={t('navbar.altLogo')}
            src={logo}
          />
        </Link>
      </Box>
      {!isOrganisationMember && roles.includes(Roles.Benevole) && <CenterPicker />}
      <Box
        sx={{
          margin: 'auto 0 auto auto',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0',
          gap: '100px',
          height: '40px',
          order: '2',
        }}
      >
        <LanguagePicker />
        <Box>
          <Typography>{t('navbar.hello')}</Typography>
          <Typography sx={{ fontSize: '16px' }}>{name}</Typography>
        </Box>

        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, m: 0 }}>
          <MoreVertIcon htmlColor="#FFFFFF" />
        </IconButton>

        <Menu
          sx={{ 'mt': '32px', '& .MuiList-root ': { padding: 0 } }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => handleCloseUserMenu()}
        >
          {roles.includes(Roles.SuperAdministrator) && (
            <Link
              underline="none"
              color="inherit"
              component={RouterLink}
              to={ROUTE.ADMINISTRATION.INDEX}
            >
              <MenuItem onClick={() => handleCloseUserMenu()}>
                <SvgIcon component={AdminPanelSettingsIcon} sx={{ marginRight: '8px' }} />
                <Typography textAlign="center">{t('navbar.administration')}</Typography>
              </MenuItem>
            </Link>
          )}
          <MenuItem
            key={'logout'}
            onClick={() => handleCloseUserMenu(() => dispatchAuthentication({ type: 'logout' }))}
          >
            <SvgIcon component={PowerSettingsNewIcon} sx={{ marginRight: '8px' }} />
            <Typography textAlign="center">{t('navbar.logout')}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};
export default NavBar;
