import { Consultation } from 'models/consultation.model';
import FormModal from 'components/modals/FormModal';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { CustomFormModalProps } from 'models/modal.model';
import { NotificationContext } from 'components/NotificationProvider';
import useCustomForm from 'hooks/useCustomForm';
import { CustomField } from 'models/form.models';
import { useModifyConsultation, useQueryConsultation } from 'api';
import consultationValidator from 'validators/consultation';
import { WaitingRoomStatus } from 'models/waitingRoom.model';

const ConsultationModal = ({
  id,
  isEdition,
  isOpen,
  onClose,
}: CustomFormModalProps<Consultation>) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const WaitingRoomStatusValues = useMemo(
    () => [
      {
        value: WaitingRoomStatus.ConsultingSocialWorker,
        label: t('waitingroom.status.consultingSocialWorker'),
      },
      {
        value: WaitingRoomStatus.ConsultingDoctor,
        label: t('waitingroom.status.consultingDoctor'),
      },
      {
        value: WaitingRoomStatus.ConsultingPsychologist,
        label: t('waitingroom.status.consultingPsychologist'),
      },
      { value: WaitingRoomStatus.ConsultingOther, label: t('waitingroom.status.consultingOther') },
    ],
    [t],
  );

  const { isLoading, data } = useQueryConsultation(id, { enabled: !!id });
  const { mutateAsync: modifyConsultation } = useModifyConsultation(id);

  const { control, handleSubmit, isDirty, register, errors } = useCustomForm<Consultation>({
    isEdition,
    isOpen,
    data,
    schema: consultationValidator,
    mutate: modifyConsultation,
  });

  const fields: CustomField[] = [
    {
      type: 'select',
      hasError: Boolean(errors.status),
      gridRow: 1,
      gridColumn: 1,
      helperText: t('form.required'),
      label: `${t('consultation.status')}`,
      isRequired: !consultationValidator.shape.status.isNullable(),
      register: register('status', {
        required: true,
      }),
      options: WaitingRoomStatusValues,
    },
    {
      type: 'text',
      hasError: Boolean(errors.remark),
      gridRow: 2,
      gridColumn: '1 / 3',
      label: t('consultation.remark'),
      register: register('remark'),
      isRequired: !consultationValidator.shape.remark.isNullable(),
    },
  ];

  const onSaved = useCallback(() => {
    sendNotification(t('notifications.consultation.edit'), 'success');
    onClose();
  }, [onClose, sendNotification, t]);

  return (
    <FormModal<Consultation>
      title={t('consultation.edit')}
      control={control}
      isEdition={true}
      isOpen={isOpen}
      onValid={handleSubmit(onSaved)}
      isDirty={isDirty}
      fields={fields}
      handleClose={onClose}
      isLoading={isLoading}
      cancelModalTitle={t('modals.consultation.title.edit', { returnObjects: true })}
    />
  );
};

export default ConsultationModal;
