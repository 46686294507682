import { useCreateIncident, useModifyIncident, useQueryIncident } from 'api';
import { NotificationContext } from 'components/NotificationProvider';
import useCustomForm from 'hooks/useCustomForm';
import useIncidentValidator from 'hooks/useIncidentValidator';
import { CustomField } from 'models/form.models';
import { Incident } from 'models/incident.model';
import { CustomFormModalProps } from 'models/modal.model';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from './FormModal';

const emptyIncident: Incident = {
  id: '',
  description: '',
  date: null,
  patientId: '',
};

const IncidentModal = ({
  id,
  isEdition,
  isOpen,
  onClose,
  patientId,
}: CustomFormModalProps<Incident> & { patientId: string }) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);

  const { isLoading, data } = useQueryIncident(id, { enabled: isEdition });
  const { mutateAsync: createIncident } = useCreateIncident(patientId);
  const { mutateAsync: modifyIncident } = useModifyIncident(id);

  const cancelModalTitle = useMemo(
    () => (isEdition ? 'modals.incidentForm.title.edit' : 'modals.incidentForm.title.add'),
    [t],
  );

  const incidentValidator = useIncidentValidator();
  const { control, handleSubmit, isDirty, register, errors } = useCustomForm<Incident>({
    isEdition,
    isOpen,
    data: isEdition ? data : emptyIncident,
    schema: incidentValidator,
    mutate: isEdition ? modifyIncident : createIncident,
  });

  const fields: CustomField[] = [
    {
      type: 'text',
      hasError: Boolean(errors.description),
      helperText: errors.description?.message,
      gridRow: 1,
      gridColumn: 1,
      label: t('incident.description'),
      register: register('description'),
      isRequired: !incidentValidator.shape.description.isNullable(),
      rows: 10,
      isMultiline: true,
    },
  ];

  const onSaved = useCallback(() => {
    sendNotification(
      isEdition ? t('notifications.incident.modify') : t('notifications.incident.add'),
      'success',
    );
    onClose();
  }, [onClose, sendNotification, t]);

  return (
    <FormModal<Incident>
      title={isEdition ? t('incident.edit') : t('incident.add')}
      control={control}
      fields={fields}
      isDirty={isDirty}
      isEdition={isEdition}
      isOpen={isOpen}
      isLoading={isLoading && isEdition}
      onValid={handleSubmit(onSaved)}
      validationButtonProps={{ text: t('incident.add') }}
      handleClose={onClose}
      cancelModalTitle={cancelModalTitle}
    />
  );
};

export default IncidentModal;
