import { Box, Link, SvgIcon, Typography } from '@mui/material';
import { AdministrationCardProps } from 'models/administration.model';
import { Link as RouterLink } from 'react-router-dom';

const AdministrationCard = ({ to, icon, viewBox, label }: AdministrationCardProps) => {
  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{
        'display': 'flex',
        'position': 'relative',
        'flexDirection': 'column',
        'borderRadius': '15px',
        'overflow': 'hidden',
        ':hover': {
          cursor: 'pointer',
        },
        'padding': '2vw',
        'backgroundColor': 'background.paper',
        'height': 'fit-content',
      }}
    >
      <Box
        sx={{
          backgroundColor: `primary.main`,
          width: '2vw',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <SvgIcon
        component={icon}
        viewBox={viewBox}
        sx={{
          width: '10vw',
          height: '5vw',
        }}
      />

      <Typography
        sx={{
          margin: '0 auto',
          color: 'darkText.main',
          whiteSpace: 'nowrap',
          fontSize: '1.5em',
        }}
      >
        {label}
      </Typography>
    </Link>
  );
};
export default AdministrationCard;
