import { Box, Button, Modal, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useAddWaitingRoom, useChangeRequestsWaitingRoom } from 'api';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { NotificationContext } from 'components/NotificationProvider';
import StatusButton from 'components/waitingRoom/StatusButton';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import { RequestStatus } from 'models/requestStatus.model';
import { ChangingStatus, WaitingRoom, WaitingRoomStatus } from 'models/waitingRoom.model';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConsultationRequestModal = ({
  isOpen,
  isEdition,
  waitingRoom,
  onClose,
}: {
  isOpen: boolean;
  isEdition: boolean;
  waitingRoom: WaitingRoom;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const dynamicTranslation = useDynamicTranslation();

  const { mutateAsync: waitingRoomAddPatient } = useAddWaitingRoom();
  const { mutateAsync: modifyRequestWaitingRoom } = useChangeRequestsWaitingRoom(waitingRoom.id);
  const { centerId } = useContext(AuthenticationContext);

  const [consultationRequests, setConsultationsRequests] = useState(
    waitingRoom.consultationRequests ?? [],
  );
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setConsultationsRequests(waitingRoom.consultationRequests);
  }, [waitingRoom, isOpen]);

  const isCheck = useCallback(
    (status: ChangingStatus) => {
      switch (status) {
        case ChangingStatus.ConsultingDoctor:
          return (
            consultationRequests?.includes(RequestStatus.DoctorConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingDoctor)
          );
        case ChangingStatus.ConsultingSocialWorker:
          return (
            consultationRequests?.includes(RequestStatus.SocialWorkConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingSocialWork)
          );
        case ChangingStatus.ConsultingPsychologist:
          return (
            consultationRequests?.includes(RequestStatus.PsyConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingPsy)
          );
        case ChangingStatus.ConsultingOther:
          return (
            consultationRequests?.includes(RequestStatus.OtherConsulted) ||
            consultationRequests?.includes(RequestStatus.WaitingOther)
          );
        default:
          return false;
      }
    },
    [consultationRequests, setConsultationsRequests],
  );

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          padding: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          color: 'primary.main',
          width: '60vw',
          minWidth: '420px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '1.25em',
            color: 'gray1.main',
          }}
        >
          {t('modals.consultationRequest.title.main')}
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'left',
            width: 'auto',
            color: 'gray1.main',
          }}
        >
          {t('modals.consultationRequest.title.date')}
        </Typography>
        <DatePicker
          sx={{
            'backgroundColor': 'background.paper',
            'borderRadius': '5px',
            'width': ' 250px',
            '& .MuiIconButton-root': {
              backgroundColor: 'background.paper',
              color: 'gray1.main',
            },
          }}
          value={date}
          onChange={(value) => {
            setDate(value ? new Date(value.valueOf()) : new Date());
          }}
        />
        <Typography
          variant="h6"
          component="h2"
          sx={{
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'left',
            width: 'auto',
            color: 'gray1.main',
          }}
        >
          {t('modals.consultationRequest.title.service')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: '16px',
            marginLeft: '24px',
            rowGap: '16px',
            gap: '48px',
          }}
        >
          {Object.keys(ChangingStatus).map(
            (status) =>
              status !== ChangingStatus.Waiting.toString() && (
                <StatusButton
                  key={status}
                  displayAsCheckbox
                  status={status as WaitingRoomStatus}
                  isCheck={isCheck(status as ChangingStatus)}
                  onClick={() => {
                    switch (status as ChangingStatus) {
                      case ChangingStatus.ConsultingDoctor:
                        consultationRequests.includes(RequestStatus.DoctorConsulted) ||
                        consultationRequests.includes(RequestStatus.WaitingDoctor)
                          ? setConsultationsRequests(
                              consultationRequests.filter((request) => {
                                return (
                                  request !== RequestStatus.DoctorConsulted &&
                                  request !== RequestStatus.WaitingDoctor
                                );
                              }),
                            )
                          : setConsultationsRequests([
                              ...consultationRequests,
                              RequestStatus.WaitingDoctor,
                            ]);

                        break;
                      case ChangingStatus.ConsultingSocialWorker:
                        consultationRequests?.includes(RequestStatus.SocialWorkConsulted) ||
                        consultationRequests?.includes(RequestStatus.WaitingSocialWork)
                          ? setConsultationsRequests(
                              consultationRequests.filter((request) => {
                                return (
                                  request !== RequestStatus.SocialWorkConsulted &&
                                  request !== RequestStatus.WaitingSocialWork
                                );
                              }),
                            )
                          : setConsultationsRequests([
                              ...consultationRequests,
                              RequestStatus.WaitingSocialWork,
                            ]);
                        break;
                      case ChangingStatus.ConsultingPsychologist:
                        consultationRequests?.includes(RequestStatus.PsyConsulted) ||
                        consultationRequests?.includes(RequestStatus.WaitingPsy)
                          ? setConsultationsRequests(
                              consultationRequests.filter((request) => {
                                return (
                                  request !== RequestStatus.PsyConsulted &&
                                  request !== RequestStatus.WaitingPsy
                                );
                              }),
                            )
                          : setConsultationsRequests([
                              ...consultationRequests,
                              RequestStatus.WaitingPsy,
                            ]);
                        break;
                      case ChangingStatus.ConsultingOther:
                        consultationRequests?.includes(RequestStatus.OtherConsulted) ||
                        consultationRequests?.includes(RequestStatus.WaitingOther)
                          ? setConsultationsRequests(
                              consultationRequests.filter((request) => {
                                return (
                                  request !== RequestStatus.OtherConsulted &&
                                  request !== RequestStatus.WaitingOther
                                );
                              }),
                            )
                          : setConsultationsRequests([
                              ...consultationRequests,
                              RequestStatus.WaitingOther,
                            ]);
                        break;
                      default:
                        break;
                    }
                  }}
                />
              ),
          )}
        </Box>

        <Box
          sx={{
            alignSelf: 'flex-end',
            marginBottom: '0',
            margin: 'auto',
            padding: 0,
            width: '485px',
            height: '40px',
          }}
        >
          <Box
            sx={{
              alignSelf: 'flex-end',
              marginBottom: '0',
              margin: 'auto',
              padding: 0,
              width: '485px',
              height: '40px',
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              sx={{
                color: 'primary.main',
                margin: 'auto',
                marginBottom: '0',
                borderRadius: '8px',
                alignSelf: 'flex-end',
                padding: 0,
                width: '224px',
                marginRight: '32px',
                height: '40px',
              }}
            >
              {t('action.cancel')}
            </Button>
            <Button
              onClick={async () => {
                const body = {
                  patientId: waitingRoom.patient.id,
                  centerId: centerId,
                  date: date,
                  consultationRequests: consultationRequests,
                };
                try {
                  isEdition
                    ? await modifyRequestWaitingRoom({
                        ...waitingRoom,
                        consultationRequests: consultationRequests,
                      })
                    : await waitingRoomAddPatient(body);
                  sendNotification(
                    isEdition
                      ? t('notifications.waitingRoom.changeRequest')
                      : t('notifications.waitingRoom.add'),
                    'success',
                  );
                  onClose();
                } catch (e) {
                  const message = (e as Error).message;
                  sendNotification(dynamicTranslation(`error.${message}`) ?? message, 'error');
                }
              }}
              variant="contained"
              sx={{
                backgroundColor: 'primary.main',
                color: 'white',
                margin: 'auto',
                marginBottom: '0',
                borderRadius: '8px',
                alignSelf: 'flex-end',
                padding: 0,
                width: '224px',
                height: '40px',
              }}
            >
              {t('waitingroom.send')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConsultationRequestModal;
