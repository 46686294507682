import { Button, Tooltip, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useDeleteIncident, useQueryIncidents } from 'api';
import { useTranslation } from 'react-i18next';
import { Incident } from 'models/incident.model';
import DataGridCustom from 'components/dataGrid/DataGridCustom';
import EditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useContext } from 'react';
import { NotificationContext } from 'components/NotificationProvider';
import { ModalContext } from 'components/ModalProvider';

const IncidentTable = ({
  patientId,
  editIncident,
}: {
  patientId: string;
  editIncident: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { openModal } = useContext(ModalContext);
  const { isLoading, data } = useQueryIncidents(patientId);
  const { mutateAsync: deleteIncident } = useDeleteIncident();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: t('incident.date'),
      sortable: false,
      width: 160,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Incident, GridTreeNodeWithRender>,
      ) => (
        <Tooltip title={params.value ? new Date(params.row.date).toLocaleString() : ''} arrow>
          <Typography sx={{ maxWidth: '100%' }}>
            {params.value ? new Date(params.row.date).toLocaleDateString() : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'description',
      headerName: t('incident.description'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: t('incident.action'),
      sortable: false,
      width: 230,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Incident, GridTreeNodeWithRender>,
      ) => (
        <>
          <Tooltip title={t('action.modify')} arrow>
            <Button
              startIcon={<EditIcon />}
              onClick={() => editIncident(params.row.id)}
              sx={{
                color: 'darkText.main',
                textDecoration: 'none',
                marginRight: '16px',
                padding: '5px 16px',
              }}
            >
              {t('action.modify')}
            </Button>
          </Tooltip>
          <Tooltip title={t('action.delete')} arrow>
            <Button
              startIcon={<DeleteForeverIcon />}
              onClick={() => {
                openModal({
                  title: t('modals.deleteIncident.title'),
                  message: t('modals.deleteIncident.message'),
                  validButton: {
                    label: t('action.delete'),
                    icon: <DeleteForeverIcon />,
                    onClick: () => {
                      deleteIncident(params.row.id);
                      sendNotification(t('notifications.incident.delete'), 'success');
                    },
                  },
                  cancelButton: {
                    label: t('action.cancel'),
                  },
                });
              }}
              sx={{ color: 'darkText.main', textDecoration: 'none', padding: '5px 16px' }}
            >
              {t('action.delete')}
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <DataGridCustom
      isPaginated={false}
      columns={columns}
      data={data}
      isLoading={isLoading}
      getRowId={(row) => row.id}
      state={{
        page: 0,
        pageSize: 0,
      }}
      hideFooter={true}
      sx={{
        'minHeight': '200px',
        '& .custom-data-grid-row': {
          backgroundColor: 'background.paper',
          borderBottom: 'none',
        },
        '& .custom-data-grid-row:hover': {
          backgroundColor: 'background.default',
        },
        '& .MuiDataGrid-virtualScroller': {
          overflow: 'auto',
        },
      }}
    />
  );
};

export default IncidentTable;
