import { InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SearchBarProps } from 'models/searchbar.model';
import { useForm } from 'react-hook-form';
import StyledIconButton from 'components/StyledIconButton';

const SearchBar = ({ placeholder, onSearch }: SearchBarProps) => {
  const { register, handleSubmit } = useForm<{ searchValue: string }>();
  const onSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    onSearch(data.searchValue);
  });

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{
        p: '0',
        display: 'flex',
        alignItems: 'center',
        width: '400px',
        height: '40px',
        borderColor: 'primary.main',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        boxShadow: 'none',
        overflow: 'hidden',
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        {...register('searchValue')}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        autoComplete="off"
      />
      <StyledIconButton
        type="submit"
        sx={{ height: '100%', width: '48px', borderRadius: 0 }}
        aria-label="search"
      >
        <SearchIcon sx={{ height: '24px', width: '24px' }} />
      </StyledIconButton>
    </Paper>
  );
};
export default SearchBar;
