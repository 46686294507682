export const QUERY_KEYS = {
  CENTER: {
    ALL: 'centers',
    PAGE: 'centersPage',
    ONE: 'center',
  },
  CITY: {
    ALL: 'cities',
    ONE: 'city',
  },
  CONSULTATION: {
    ALL: 'consultations',
    ONE: 'consultation',
  },
  COUNTRY: {
    ALL: 'countries',
    ONE: 'country',
  },
  INCIDENT: {
    ALL: 'incidents',
    ONE: 'incident',
  },
  JOB: {
    ALL: 'jobs',
    ONE: 'job',
  },
  ORGANISATION: {
    ALL: 'organisations',
    ONE: 'organisation',
  },
  PATIENT: {
    ALL: 'patients',
    FORBIDDEN: 'forbidden',
    ONE: 'patient',
  },
  ROLE: {
    ALL: 'roles',
    ONE: 'role',
  },
  USER: {
    ALL: 'users',
    ONE: 'user',
  },
  WAITING_ROOM: {
    ALL: 'waiting-rooms',
    ONE: 'waiting-room',
  },
};
