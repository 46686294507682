export enum RequestStatus {
  WaitingDoctor = 'WaitingDoctor',
  WaitingSocialWork = 'WaitingSocialWork',
  WaitingPsy = 'WaitingPsy',
  WaitingOther = 'WaitingOther',
  DoctorConsulted = 'DoctorConsulted',
  SocialWorkConsulted = 'SocialWorkConsulted',
  PsyConsulted = 'PsyConsulted',
  OtherConsulted = 'OtherConsulted',
}
