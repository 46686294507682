import { Box, Button, SxProps, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WaitingRoomCard from './WaitingRoomCard';
import { useQueryWaitingRooms } from 'api';
import { useContext, useState } from 'react';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { WaitingRoomListProps, WaitingRoomStatus } from 'models/waitingRoom.model';
import { DatePicker } from '@mui/x-date-pickers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { RequestStatus } from 'models/requestStatus.model';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import { useTheme } from '@mui/material/styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

enum Filter {
  doctor = 'doctor',
  socialWorker = 'socialWorker',
  psychologist = 'psychologist',
  other = 'other',
  left = 'left',
  waiting = 'waiting',
  all = 'all',
}

const styleFilter: SxProps = {
  'backgroundColor': 'background.paper',
  'color': 'darkText.main',
  'margin': 'auto',
  'width': '70px',
  'textAlign': 'center',
  'borderRadius': '5px',
  ':hover': {
    cursor: 'pointer',
  },
};

const WaitingRoomList = ({
  sx,
  onClick,
  isListHistoricized = false,
  onClose = () => {
    return;
  },
}: WaitingRoomListProps) => {
  const { t } = useTranslation();
  const dynamicTranslation = useDynamicTranslation();
  const theme = useTheme();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState(Filter.all);
  const { centerId } = useContext(AuthenticationContext);
  const { data } = useQueryWaitingRooms(centerId, timestamp);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16px',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          'backgroundColor': 'primary.main',
          'boxShadow': '0px 4px 10px rgba(0, 0, 0, 0.12)',
          'borderRadius': '15px',
          'padding': '16px',
          'gap': '8px',
          'display': 'grid',
          'gridTemplateColumns': '2fr 2fr',
          'gridTemplateRows': 'repeat(3, auto)',
          '& > *': {
            color: 'background.paper',
            fontSize: '16px',
            lineHeight: '140%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gridRow: '1',
            gridColumn: '1 / 3',
          }}
        >
          <Typography sx={{ fontSize: '24px' }}>{t('waitingroom.title')}</Typography>
          <Tooltip
            title={
              <Box>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumber')} ${data?.length ?? 0}`}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumberDoctor')} ${
                    data?.filter(
                      (waitingRoom) =>
                        waitingRoom.consultationRequests.includes(RequestStatus.DoctorConsulted) ||
                        waitingRoom.consultationRequests.includes(RequestStatus.WaitingDoctor),
                    ).length ?? 0
                  }`}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumberSocialWork')} ${
                    data?.filter(
                      (waitingRoom) =>
                        waitingRoom.consultationRequests.includes(
                          RequestStatus.SocialWorkConsulted,
                        ) ||
                        waitingRoom.consultationRequests.includes(RequestStatus.WaitingSocialWork),
                    ).length ?? 0
                  }`}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumberPsy')} ${
                    data?.filter(
                      (waitingRoom) =>
                        waitingRoom.consultationRequests.includes(RequestStatus.PsyConsulted) ||
                        waitingRoom.consultationRequests.includes(RequestStatus.WaitingPsy),
                    ).length ?? 0
                  }`}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumberOther')} ${
                    data?.filter(
                      (waitingRoom) =>
                        waitingRoom.consultationRequests.includes(RequestStatus.OtherConsulted) ||
                        waitingRoom.consultationRequests.includes(RequestStatus.WaitingOther),
                    ).length ?? 0
                  }`}
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  {`${t('waitingroom.patientNumberWaiting')} ${
                    data?.filter((waitingRoom) => waitingRoom.status === WaitingRoomStatus.Waiting)
                      .length ?? 0
                  }`}
                </Typography>
              </Box>
            }
            placement="left"
            arrow
          >
            <HelpOutlineIcon />
          </Tooltip>
        </Box>
        <Button
          sx={{
            color: 'background.paper',
            width: 'auto',
            gridRow: 2,
            gridColumn: 1,
            p: 0,
            m: 0,
          }}
          endIcon={isFilterOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          variant="text"
          onClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
        >
          {t('waitingroom.filter')}
        </Button>
        <Box
          sx={{
            display: 'grid',
            width: '100%',
            gridRow: 3,
            gridColumn: '1/3',
            rowGap: '12px',
          }}
        >
          {Object.keys(Filter).map((value, index) => (
            <Typography
              key={value}
              sx={{
                ...styleFilter,
                backgroundColor:
                  (value as Filter) === filter ? theme.palette.success.light : 'background.paper',
                gridRow: Math.floor(index / 4) + 1,
                gridColumn: (index % 4) + 1,
              }}
              onClick={() => setFilter(value as Filter)}
              hidden={!isFilterOpen}
            >
              {(dynamicTranslation(`waitingroom.validation.${value}`) ?? '').toUpperCase()}
            </Typography>
          ))}
        </Box>
        {isListHistoricized && (
          <Box
            sx={{
              gridRow: '2',
              gridColumn: '1/3',
              w: '100%',
            }}
          >
            <Typography sx={{ fontSize: '16px' }}>{t('waitingroom.date')}</Typography>
            <DatePicker
              sx={{
                'backgroundColor': 'background.paper',
                'borderRadius': '5px',
                'width': '100%',
                '& .MuiIconButton-root': {
                  backgroundColor: 'background.paper',
                  color: 'gray1.main',
                },
              }}
              value={timestamp}
              onChange={(value) => {
                setTimestamp(value ? value.valueOf() : Date.now());
                onClose();
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingRight: '8px',
          paddingBottom: '8px',
        }}
      >
        {data
          ?.filter((waitingRoom) => {
            switch (filter) {
              case Filter.doctor:
                return (
                  waitingRoom.consultationRequests.includes(RequestStatus.DoctorConsulted) ||
                  waitingRoom.consultationRequests.includes(RequestStatus.WaitingDoctor)
                );
              case Filter.socialWorker:
                return (
                  waitingRoom.consultationRequests.includes(RequestStatus.SocialWorkConsulted) ||
                  waitingRoom.consultationRequests.includes(RequestStatus.WaitingSocialWork)
                );
              case Filter.psychologist:
                return (
                  waitingRoom.consultationRequests.includes(RequestStatus.PsyConsulted) ||
                  waitingRoom.consultationRequests.includes(RequestStatus.WaitingPsy)
                );
              case Filter.other:
                return (
                  waitingRoom.consultationRequests.includes(RequestStatus.OtherConsulted) ||
                  waitingRoom.consultationRequests.includes(RequestStatus.WaitingOther)
                );
              case Filter.left:
                return (
                  waitingRoom.status === WaitingRoomStatus.EndConsultation ||
                  waitingRoom.status === WaitingRoomStatus.Refused
                );
              case Filter.waiting:
                return waitingRoom.status === WaitingRoomStatus.Waiting;
              default:
                return true;
            }
          })
          .map((waitingroom) => (
            <WaitingRoomCard
              key={waitingroom.id}
              status={waitingroom.status as WaitingRoomStatus}
              firstname={waitingroom.patient.firstname}
              lastname={waitingroom.patient.lastname}
              isIncident={waitingroom.patient.isIncident}
              consultationRequests={waitingroom.consultationRequests}
              onClick={() => onClick(waitingroom.id)}
            />
          ))}
      </Box>
    </Box>
  );
};
export default WaitingRoomList;
