import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableState } from 'models/dataGridCustom.model';
import { Patient } from 'models/patient.model';
import DataGridCustom from './dataGrid/DataGridCustom';
import { useQueryForbiddenPatients, useQueryPatients } from 'api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Box, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { emptyPage } from 'models/api.model';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const PatientTable = ({
  sx,
  filter,
  isForbidden,
  editPatient,
}: {
  sx: SxProps;
  filter: string;
  isForbidden: boolean;
  editPatient: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const [pageState, setPageState] = useState<TableState>({
    page: 0,
    pageSize: 0,
  });
  const isNotEmpty = useMemo(() => filter.trim().length > 0, [filter]);
  const { isLoading, data } = useQueryPatients(pageState.page, pageState.pageSize, filter, {
    enabled: isNotEmpty,
  });

  const { data: forbiddenPatients } = useQueryForbiddenPatients(
    pageState.page,
    pageState.pageSize,
    {
      enabled: isForbidden,
    },
  );

  const columns: GridColDef[] = [
    {
      field: 'dsiNumber',
      width: 100,
      headerName: t('patient.dsiNumber'),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => (
        <Tooltip title={t('action.copy')} arrow>
          <Button
            startIcon={<ContentCopyIcon color="primary" />}
            variant="text"
            onClick={() => navigator.clipboard.writeText(params.row.dsiNumber)}
          >
            {params.row.dsiNumber}
          </Button>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: t('patient.lastname'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => (
        <Typography sx={{ maxWidth: '100%' }}>
          {params.value ? params.row.lastname.toLocaleUpperCase() : ''}
        </Typography>
      ),
    },
    {
      field: 'firstname',
      headerName: t('patient.firstname'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'birthDate',
      headerName: t('patient.birthdate'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => (
        <Tooltip
          title={params.value ? new Date(params.row.birthDate).toLocaleDateString() : ''}
          arrow
        >
          <Typography sx={{ maxWidth: '100%' }}>
            {params.value ? new Date(params.row.birthDate).toLocaleDateString() : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'contactNumber',
      headerName: t('patient.contactNumber'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'remark',
      headerName: t('patient.remark'),
      sortable: false,
      flex: 2,
    },
    {
      field: 'isBan',
      width: 100,
      headerName: t('patient.detail'),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Patient, GridTreeNodeWithRender>,
      ) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title={t('action.detail')} arrow>
              <IconButton
                onClick={() => {
                  editPatient(params.id.toString());
                }}
              >
                <ContactPageIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={params.row?.isBan ? t('patient.isForbidden') : t('patient.isNotForbidden')}
              arrow
            >
              {params.row?.isBan ? (
                <LockOutlinedIcon color="error" sx={{ margin: '8px' }} />
              ) : (
                <></>
              )}
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    setPageState({ ...pageState, page: 0 });
  }, [filter]);

  return (
    <>
      <DataGridCustom
        isPaginated={true}
        sx={sx}
        data={isForbidden ? forbiddenPatients : isNotEmpty ? data : emptyPage}
        isLoading={isNotEmpty && isLoading}
        state={pageState}
        columns={columns}
        getRowId={(row) => row.id}
        onPaginationModelChange={(newPageModel: TableState) => {
          setPageState({
            page: newPageModel.page,
            pageSize: newPageModel.pageSize,
          });
        }}
      />
    </>
  );
};
export default PatientTable;
